import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import axios from "axios"

import { Box, Button, Form, FormField, Grid, Heading, Text, TextArea } from "grommet"
import { StatusGood } from "grommet-icons"
import Layout from "../components/layout"
import ImageSharpImage from "../components/imageHandling/imageSharpImage"
import StyledBackgroundSection from "../components/backgroundSection"

const FormFieldLabel = ({ required, label, ...rest }) => {
  const [requiredFieldIndicator] = useState(required)
  const [fieldLabel] = useState(label)

  return (
    <FormField
      label={
        requiredFieldIndicator ? (
          <Box direction="row">
            <Text>{fieldLabel}</Text>
            <Text color="status-critical">*</Text>
          </Box>
        ) : (
          fieldLabel
        )
      }
      {...rest}
    />
  )
}

// The MaskedInput doesn't do validation but does force input according to certain patterns - still need validation
const ContactFormEmail3 = () => {
  const useMountEffect = (fun) => useEffect(fun, [])

  // DET FIXME put this in graphql query
  const contactBackendUrl2 = "https://9d5f1637.ngrok.io/test/"
  const contactBackendUrl = "https://marketing-backend-276619.uc.r.appspot.com/test/"
  const contactPhone = "+15614378001"

  // the captchav2 will be in a div identified like this
  const contactFormRecaptchaV2DivIdentifier = "ContactFormRecaptcha"
  const contactFormRecaptchaV2SiteKey = "6LdCO-8UAAAAAOwztwS812hND6_jYUMu5C1Ypf1h"

  const [redirectHome, setRedirectHome] = useState(false)
  const [submitIsValid, setSubmitIsValid] = useState(false)

  // don't really need this
  const [visible, setVisible] = useState(true) // true is the inital state

  const [nameToSend, setNameToSend] = React.useState("")
  const [emailToSend, setEmailToSend] = React.useState("")
  const [phoneToSend, setPhoneToSend] = React.useState("")
  const [messageToSend, setMessageToSend] = React.useState("")
  // will hold the recaptcha v2 token - can be cleared before submit if expired
  const [tokenToSend, setTokenToSend] = React.useState(null)

  const [recaptchaV2TokenReady, setRecaptchaV2TokenReady] = React.useState(false)
  const [contactFormReCaptchaLoaded, setContactFormReCaptchaLoaded] = React.useState(false)

  /*
      this function is triggered as recaptchav2 data-expired-callback - see div
      The render function sets window property to name of this function
   */
  const contactFormReCaptchaExpired = () => {
    // disable the submit again
    console.log("Contact form recaptcha expired - disabling")
    setRecaptchaV2TokenReady(false)
    setTokenToSend(null)
  }

  /*
   * This function is a callback from ReCaptcha V2 and is called when a
   * network connectivity error is there - nothing to do but retry.
   */
  const contactFormReCaptchaError = _ => {
    console.log("Google ReCaptcha V2 error (transient network etc.)")
    alert("There is a temporary connectivity issue\nPlease refresh the page or try again later")
  }
  /*
      this function is triggered as recaptchav2 token ready (user passed) -
      see div and the render func that sets the window property
   */
  const contactFormReCaptchaGetToken = (token) => {
    console.log("User verify complete on recaptcha")
    window.grecaptcha.ready(_ => {
//      console.log("The response = " + token)
      /*
       * Now signal that the submit button can be enabled -
       * this could expire (see callback)
       *
       * We use to enable the submit button
       *
       * Google times it out so we may have state cleared again -
       * see data-expired-callback
       * Set token before the flag to ensure state is ok
       */
      setTokenToSend(token)
      setRecaptchaV2TokenReady(true)
    })

  }

  // this render function is called after page is loaded - see useEffect below
  const renderRecaptchaV2 = _ => {
    window.grecaptcha.ready(_ => {
      console.log("Rendering recaptcha v2 for contact form")
      window.grecaptcha.render(contactFormRecaptchaV2DivIdentifier, {
        "sitekey": contactFormRecaptchaV2SiteKey
      })
      // the window property is same name as what is in div but does not need to be same name as function
      window.contactFormReCaptchaGetToken = contactFormReCaptchaGetToken
      window.contactFormReCaptchaExpired = contactFormReCaptchaExpired
      window.contactFormReCaptchaError = contactFormReCaptchaError
    })
  }

  const recaptchaV2Loaded = () => {
    console.log("recaptchav2 token is ready")
    /*
        getting here means components are loaded so it is safe to render
        Setting the contactFormReCaptchaLoaded variable will trigger
        (via useEffect on a dependent property change) the render
     */
    setContactFormReCaptchaLoaded(true)
  }

  const loadRecaptchaV2 = () => {
    console.log("Running load recaptcha v2")
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?render=explicit"
    script.className = "g-recaptcha"
    script.async = true
    script.defer = true
    script.onload = recaptchaV2Loaded
    window.onload = recaptchaV2Loaded
    document.body.appendChild(script)
    console.log("I ran load")
  }

  useEffect(() => {
    if (redirectHome) {
      console.log("Redirecting from form, submit valid = " + submitIsValid)
      navigate("/")
    }
//    console.log("Hello in effect")
  }, [redirectHome, submitIsValid])

  /*
   * This only loads once, when the page is loaded.  It drops a script
   * into the page and sets a load callback that will finish the work
   */
  useMountEffect(loadRecaptchaV2)

  /*
   * Use this hook to render the captcha for contact form -
   * it will only be done when load request indicates a need
   */
  useEffect(() => {
    console.log("contactFormReCaptchaLoaded state = " + contactFormReCaptchaLoaded)
    // not sure we need to do this but if we don't set to false below then we get multiple render attempts
    if (contactFormReCaptchaLoaded) {
      console.log("Time to render the captcha")
      renderRecaptchaV2()
    }
    // now we loaded - don't do again unless page refreshes
    setContactFormReCaptchaLoaded(false)
  }, [contactFormReCaptchaLoaded])


  /*
   *  This function takes care of the post, including the token
   *  from the recaptcha v2 used for contact form
   */
  const submitBackend = (data) => {
    /*
     *  Now prepare data for post
     *  name: strip leading and trailing whitespace
     *  email: same
     *  message: same
     *
     *  The values MUST have data so it is ok to do the strip function
     *  assuming they are ok, but we'll build up anyway
     */
    console.log("Posting contact request with " + JSON.stringify(data))
//    console.log("operating mode = " + JSON.stringify(process.env.NODE_ENV))
//    console.log("Env variable for contact = " + JSON.stringify(process.env.INBOUND_CONTACT_SLACK_WEBHOOK_URI))


    /*
     * Note the use of application/x-www-form-urlencoded for the header
     * Slack has issues with CORS - can't use application/json
     */
    axios.post(process.env.INBOUND_CONTACT_SLACK_WEBHOOK_URI,
      {
        blocks: [
          {
            type: "header",
            text: {
              type: "plain_text",
              text: "New inbound contact request from " + data.name.trim()
            }
          },
          {
            type: "section",
            fields: [
              {
                type: "mrkdwn",
                text: "*Email:*\n" + data.email.trim().toLowerCase()
              },
              {
                type: "mrkdwn",
                text: "*Phone:*\n" + data.phone.trim() ? data.phone : null
              }
            ]
          },
          {
            type: "section",
            fields: [{
              type: "mrkdwn",
              text: "*Message:*\n" + messageToSend.trim()
            }]
          }
        ]
      },
      {
        headers:
          {
            "Content-Type":
              "application/x-www-form-urlencoded"
          }
      })
      .then(res => {
//        console.log("POST response = " + res.status)
//        console.log("POST response data = " + JSON.stringify(res.data))
        if (res.status === 200) {
//          console.log("Ready for redirect")
          setSubmitIsValid(true)
          setRedirectHome(true)
          window.alert("Request submitted - we will be in touch promptly!")
        } else {
          console.log("Unsuccessful submit on contact data")
        }
      })
      .catch(error => {
        console.log(JSON.stringify(error))
        // if no server, error.message will be "Network Error"
        window.alert("We are having trouble reaching the server with your information.  Please try again later!")
      })
  }

  /*
    Pattern on net found
    (([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})

    Our pattern allows +<numbers> and (xxx)yyy-zzzz optionally with spaces

                    { regexp: /^[a-z]{1, }[\s][a-z]{3,}$/i, message: 'Enter first and last name', status: "error" },

   */
  // the first regexp in validate for name will block further testing until alphabetic chars entered

//  const contactImageUrl = ImageSharpUrl(imageIdentifier="CONTACT-US", debugConsole=true);

//  console.log('The URL = ' + contactImageUrl[0])

  /*
   *  Pass this into the background section as a function to be invoked there since React doesn't want objects
   */
  const contactFormContent = _ => {
    return (
      <Box
        fill
        align="center"
        justify="center"
        background={{
          opacity: "weak",
          color: "light-1"
        }}
      >
        <Box width="medium">
          <Form
            validate={"blur"}
            onReset={event => {
              console.log("Form reset")
              setNameToSend("")
              setEmailToSend("")
              setPhoneToSend("")
              setMessageToSend("")
            }}
            onSubmit={event => {
              submitBackend(event.value)

              // strip the whitepsace from the phone number before using
            }}
          >
            <FormFieldLabel
              label="Name"
              name="name"
              value={nameToSend}
              onChange={event => setNameToSend(event.target.value)}
              required={true}
              validate={[
                { regexp: /^[a-z]/i },
                name => {
                  console.log("name value = " + name)
                  const nameErrorMessage = "Please enter first and last name"
                  if (name === undefined || name.length < 4) {
                    return nameErrorMessage
                  }
                  const nameArray = name.split(" ")
                  if (nameArray.length < 2 || nameArray[0].length < 2 || nameArray[nameArray.length - 1].length < 3) {
                    return nameErrorMessage
                  }
                  return {
                    message: (
                      <Box align="end">
                        <StatusGood/>
                      </Box>
                    ),
                    status: "info"
                  }
                }
              ]}
            />
            <FormFieldLabel
              label="Email"
              name="email"
              value={emailToSend}
              onChange={event => setEmailToSend(event.target.value)}
              required={true}
              validate={[
                {
                  regexp: /^[a-z0-9!#$%&‘*+–/=?^_`{|}~]/i,
                  message: "Valid email address",
                  status: "error"
                },
                email => {
                  /*
                   * The regexp above just keeps an error from appearing before first char entered.  Validation rules.
                   * 1) First char must be one of a-z0-9!#$%&‘*+–/=?^_`.{|}~
                   * 2) Other parts of local can include any of a-z0-9!#$%&‘*+–/=?^_`.{|}~.
                   *    (note period)
                   * 3) Local cannot end in period
                   * 4) Domain must not begin or end with hyphen (and by extension period since we need domain name)
                   */
                  // First make sure include @ separator, but not at first
                  const parseErrorMessage = "Valid email address needed"
                  if (emailToSend === undefined || emailToSend.length < 5) {
                    return parseErrorMessage
                  }
                  // trim leading and trailing whitespace or other things worn't work
                  setEmailToSend(emailToSend.trim())
                  console.log("Now email = \"" + email + "\"")
                  if (emailToSend.startsWith(".") || emailToSend.startsWith("@")) {
                    return parseErrorMessage
                  }
                  if (emailToSend.endsWith(".") || emailToSend.endsWith("@") || emailToSend.endsWith("-")) {
                    return parseErrorMessage
                  }
                  // this will make sure exactly one @ separator exists
                  const emailComponents = emailToSend.split("@")
                  if (emailComponents.length !== 2) {
                    return parseErrorMessage
                  }
                  console.log("Email address components = " + emailComponents)
                  /*
                   *  Now validate the local part
                   */
                  // local part cannot end in .
                  if (emailComponents[0].endsWith(".")) {
                    return parseErrorMessage
                  }

                  /*
                   *  Now validate the domain part
                   *  1) Must have domain and tld (2 elements)
                   *  2) domain part = min 3, max 63
                   *  3) tld part same - technically this is not sufficient
                   */
                  const domainPart = emailComponents[1].split(".")
                  console.log("Domain part = " + domainPart)
                  if (domainPart.length < 2) {
                    return parseErrorMessage
                  }
                  /*
                   * We can't really block subdomain rules - they can be 1 char
                   *  So if only two components, check both.  Otherwise,
                   *  check last two
                   *  DET FIXME - this is technically blocking x.zyz.com
                   */
                  for (let i = 0; i < domainPart.length; i++) {
                    console.log("This element of domain = " + domainPart[i])

                    if (domainPart[i].length < 3 || domainPart[i].length > 63) {
                      return parseErrorMessage
                    }
                  }

                  console.log("Email parsing name = " + emailToSend)

                  // if we get here, it is a pass so go to next
                  return {
                    message: (
                      <Box align="end">
                        <StatusGood/>
                      </Box>
                    ),
                    status: "info"
                  }
                }
              ]}
            />
            <FormFieldLabel
              label="Phone"
              name="phone"
              value={phoneToSend}
              onChange={event => setPhoneToSend(event.target.value)}
              required={false}
              validate={[
                {
                  regexp: /^[0-9]/i,
                  message: "Please enter phone number",
                  status: "error"
                },
                phone => {
                  console.log("phone value = " + phoneToSend)
                  const phoneErrorMessage = "Please enter phone number"
                  // if not required, ok to be blank - DET FIXME not figured out how to check required prop here
                  if (phoneToSend === undefined || phoneToSend.length === 0) {
                    return {
                      message: "",
                      status: "info"
                    }
                  }
                  if (phoneToSend.length < 10) {
                    return phoneErrorMessage
                  }
                  /*
                   *  Validation on phone
                   *  Parse to this regexp which allows US format (xxx)yyy-zzzz and E.164 +xxxxxxxxx
                   *  DET FIXME figure out how to update the value trimming it
                   */
                  if (phoneToSend.startsWith(" ") || phoneToSend.endsWith(" ")) {
                    return phoneErrorMessage
                  }
                  if (!phoneToSend.match(/^[+][0-9]{11,17}$/)) {
                    console.log("Unable to match phone number in E164  format" + phoneToSend)
                    // try US format
                    if (!phoneToSend.match(/^[(]?[0-9]{3}[)]?[\s]{0,}[0-9]{3}[\s]?[-]?[\s]{0,}[0-9]{4}$/)) {
                      console.log("Unable to match phone number in US 10 digit format" + phoneToSend)
                      if (!phoneToSend.match(/^[+]{1}[0-9]{1,3][-]?[(]?[0-9]{1,3}[)]?[\s]{0,}[0-9]{2,4}[\s]{0,}[0-9]{6,8}$/)) {
                        console.log("Unable tp match with leading + and space-tolerant parsing")
                        return phoneErrorMessage
                      }
                    }
                  }
                  return {
                    message: (
                      <Box align="end">
                        <StatusGood/>
                      </Box>
                    ),
                    status: "info"
                  }
                }
              ]}
            />
            <FormField
              label="Message"
              name="messageToSend"
              required={true}
              validate={[
                {
                  regexp: /^[a-z]/i,
                  message: "Please enter a message for us"
                },
                messageToSend => {
                  const validationErrorMessage = "Please enter a message for us"
                  console.log("Note = " + messageToSend)
                  if (messageToSend === undefined) {
                    return validationErrorMessage
                  }
                  if (messageToSend.length === 0) {
                    return validationErrorMessage
                  }

                  /*
                   *  Now count the words and throw error if < 3
                   */
                  const messageWordCount = messageToSend.replace(/(^\s*)|(\s*$)/gi, "")
                    .replace(/[ ]{2,}/gi, " ")
                    .replace(/\n /, "\n")
                    .split(" ")
                    .length
                  console.log("Message word count = " + messageWordCount)
                  if (messageWordCount < 2) {
                    return validationErrorMessage
                  }

                  // ok we will take it - we'll strip leading and ending spaces before sending
                  return {
                    message: (
                      <Box align="end">
                        <StatusGood/>
                      </Box>
                    ),
                    status: "info"
                  }
                }
              ]}
            >
              <TextArea
                name="messageToSend"
                value={messageToSend}
                onChange={event => setMessageToSend(event.target.value)}
              />
            </FormField>
            <Box direction={"column"} justify={"between"}>
              <Box direction="row" justify="between" margin={{ top: "medium" }}>
                <Button
                  label="Cancel"
                  onClick={event => {
                    console.log("Cancel click redirect = " + redirectHome)
                    setRedirectHome(true)
                    console.log("Cancel click redirect = " + redirectHome)
                  }}
                />
                <Button
                  type="reset"
                  label="Reset"/>
                <Button
                  type="submit"
                  label="Submit"
                  disabled={!recaptchaV2TokenReady}
                  primary
                />
                <Text margin={{ left: "small" }} size="small" color="status-critical">
                  * Required Field
                </Text>
              </Box>
            </Box>
          </Form>
        </Box>
      </Box>
    )
  }

  return (
    <Layout>
      <Grid
        rows={["auto", "fill"]}
        columns={["auto"]}
        align={"center"}
        responsive={true}
        overflow={"auto"}
        pad={"small"}
        areas={[
          { name: "contactForm", start: [0, 1], end: [0, 1] },
          { name: "captchav2", start: [0, 0], end: [0, 0] }
        ]}
      >
      </Grid>
      <Box direction={"column"} gridArea={"contactForm"}>
        <StyledBackgroundSection
          imageIdentifier={"CONTACT-US"}
          opacity={"10%"}
          debugConsole={false}
          content={contactFormContent}
        />
        <Box gridArea={"captchav2"} pad={"small"} margin={{ top: "medium", bottom: "medium" }} align={"center"}>
          <div
            id={contactFormRecaptchaV2DivIdentifier}
            className="g-recaptcha"
            data-sitekey={contactFormRecaptchaV2SiteKey}
            data-callback="contactFormReCaptchaGetToken"
            data-expired-callback="contactFormReCaptchaExpired"
            data-error-callback="contactFormReCaptchaError"
          />
        </Box>
      </Box>
    </Layout>
  )
}

export default ContactFormEmail3