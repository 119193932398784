import React from "react"
import styled from "styled-components"
import {Box} from "grommet"
import ImageSharpData from "./imageHandling/imageSharpData"
import BackgroundImage from "gatsby-background-image"
import PropTypes from "prop-types"

const BackgroundImageStyledOpacity = styled(BackgroundImage)`
    &::before,
    &::after {
      filter: opacity(
        ${({ opacity }) => {
  return opacity
}}
      );
    }
    `

const BackgroundSection = (props) => {

  const imageSharp = ImageSharpData(props)
  if (!imageSharp || imageSharp.length === 0) {
    console.log("Unable to locate image in background section")
    return null
  }
  if (props.debugConsole) {
    console.log("Image sharp in background section: " + JSON.stringify(imageSharp, null, 2))
    console.log("Image sharp length = " + imageSharp.length)
    console.log('Opacity = ' + props.opacity + ', and darken = ' + props.darken)
  }

  const imageData = imageSharp[0].node.childImageSharp.fluid
  return (
    <BackgroundImageStyledOpacity
      opacity={props.opacity}
      darken={props.darken}
      Tag="section"
      className={props.className}
      fluid={imageData}
    >
      {props.content()}
    </BackgroundImageStyledOpacity>
  )
}

BackgroundSection.propTypes = {
  imageName: PropTypes.string,
  imageIdentifier: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  catalog: PropTypes.string,
  debugConsole: PropTypes.bool,
  content: PropTypes.func.isRequired,
  opacity: PropTypes.string,
}

BackgroundSection.defaultProps = {
  imageName: "",
  imageIdentifier: "",
  alt: "",
  catalog: "STANDARD",
  debugConsole: false,
  opacity: '100%',
}

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection